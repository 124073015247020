<template>
  <div class="app-header">
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <app-header-account-sets class="ml-10px"/>
        <div class="h-input-group" v-if="currentAccountSets">
          <Input :value="currentAccountDate" disabled class="d-input"/>
          <span class="h-input-addon h-icon-calendar as-icon-calendar"></span>
        </div>
      </div>
      <div class="flex items-center">
        <i v-if="webConfig.qrCode" class="fi fi-kf w-20px h-20px text-hover" @click="showKf()"></i>
        <i class="fi fi-bz w-20px h-20px text-hover mx-20px" @click="showAbout()"></i>
        <div class="app-header-info mr-16px">
          <DropdownMenu className="app-header-dropdown" offset="0 5" :width="150" placement="bottom-end" :datas="infoMenu" @clickItem="trigger">
            <Avatar :src="avatarUrl" :width="30"><span>{{ User.realName }}</span></Avatar>
          </DropdownMenu>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {defineAsyncComponent, h} from 'vue';
import {mapGetters, mapState} from 'vuex';
import manba from 'manba';
import AppHeaderAccountSets from "./modules/app-header-account-sets";
import {layer} from "@layui/layer-vue";
import Renewal from "@/views/app/Renewal";
import setting from "@api/setting";
import About from "@/views/About.vue";
import KeFu from "@/views/KeFu.vue";
import {notice} from "heyui.ext";
import common from "@api/common";

export default {
  components: {
    AppHeaderAccountSets
  },
  data() {
    return {
      searchText: '',
      currentAccountDate: null,
      infoMenu: [
        {key: 'PersonalSetting', title: '账号信息', icon: 'h-icon-user', height: '430px'},
        {key: 'ChangePassword', title: '修改密码', icon: 'h-icon-lock', height: '430px'},
        {key: 'ChangePhoneNumber', title: '账号变更', icon: 'h-icon-link', height: '430px'},
        {key: 'AiConfig', title: 'AI配置', icon: 'h-icon-setting', height: '350px'},
        {key: 'logout', title: '退出登录', icon: 'h-icon-outbox'}
      ]
    };
  },
  computed: {
    ...mapState(['User', 'currentAccountSets', 'webConfig']),
    ...mapGetters(['checkoutList']),
    sliderCollapsed: {
      get() {
        return this.$store.state.sliderCollapsed;
      },
      set(value) {
        this.$store.commit('updateSliderCollapse', value);
      }
    },
    accountDate() {
      return this.currentAccountSets ? manba(this.currentAccountSets.currentAccountDate).format("YYYY年MM月") : null;
    },
    avatarUrl() {
      return this.User.avatarUrl || '/images/yun.png'
    },
    dOp() {
      let filter = this.checkoutList.filter(val => val.status !== 2);
      return {
        start: filter[filter.length - 1].checkDate,
        end: filter[0].checkDate,
      }
    }
  },
  watch: {
    accountDate() {
      this.currentAccountDate = this.accountDate;
    }
  },
  mounted() {
    this.currentAccountDate = this.accountDate;
    if (this.currentAccountSets && this.currentAccountSets.effectiveDate) {
      let day = manba(this.currentAccountSets.effectiveDate).distance(manba(), manba.DAY);
      if (day >= 0) {
        if (day < 15) {
          notice({
            type: 'warn',
            title: "续期提醒",
            content: `您当前账套将于 <b class="red-color">${day + 1}天</b> 后过期<br/>请及时联系客服续期～`,
            timeout: 0,
            buttons: [
              {
                name: '续期',
                style: 'primary',
                type: 'confirm'
              }
            ],
            events: {
              confirm: e => {
                this.doRenewal()
                e.close();
              }
            }
          });
        }
      } else {
        notice({
          type: 'error',
          title: "过期提醒",
          content: `您当前账套已过期，请联系客服续期～`,
          timeout: 0,
          buttons: [
            {
              name: '续期',
              style: 'primary',
              type: 'confirm'
            }
          ],
          events: {
            confirm: e => {
              this.doRenewal()
              e.close();
            }
          }
        });
      }
    }
  },
  methods: {
    trigger(data) {
      if (data === 'logout') {
        common.logout().then(() => {
          window.location.replace("/");
        });
      } else {
        const find = this.infoMenu.find(val => val.key === data);
        layer.open({
          title: find.title,
          shadeClose: false,
          zIndex: 999,
          area: ['600px', find.height],
          content: h(defineAsyncComponent(() => import('@/views/personal/' + data + '.vue')))
        });
      }
    },
    showAbout() {
      layer.open({
        title: "关于" + this.webConfig.name,
        zIndex: 999,
        area: ['570px', 'auto'],
        content: h(About)
      });
    },
    showKf() {
      layer.open({
        title: "联系客服",
        zIndex: 999,
        area: ['400px', '300px'],
        content: h(KeFu)
      });
    },
    doRenewal() {
      const layerId = layer.open({
        title: "账套续期",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['500px', 'auto'],
        content: h(Renewal, {
          onClose() {
            layer.close(layerId);
          },
          onSuccess() {
            layer.close(layerId);
          }
        })
      });
    },
    accountDateChange(val) {
      let date = manba(val.replace("年", "-").replace("月", "-") + "01").format();
      setting.accountSets.updateCurrentAccountDate(date).then(() => {
        this.$store.dispatch('init');
      })
    }
  }
};
</script>
<style lang="less">

.app-header {
  color: rgba(49, 58, 70, 0.8);

  .h-autocomplete {
    line-height: 1.5;
    float: left;
    margin-top: 15px;
    margin-right: 20px;
    width: 120px;

    &-show, &-show:hover, &-show.focusing {
      outline: none;
      box-shadow: none;
      border-color: transparent;
      border-radius: 0;
    }

    &-show.focusing {
      border-bottom: 1px solid #eee;
    }
  }

  &-info &-icon-item {
    cursor: pointer;
    display: inline-block;
    float: left;
    padding: 0 15px;
    height: @layout-header-height;
    line-height: @layout-header-height;
    margin-right: 10px;

    &:hover {
      background: @hover-background-color;
    }

    i {
      font-size: 18px;
    }

    a {
      color: inherit;
    }

    .h-badge {
      margin: 20px 0;
      display: block;
    }
  }

  .app-header-icon-item {
    margin-left: 20px;

    button {
      background-color: #ffffff;
    }
  }

  &-dropdown {
    float: right;
    padding: 0 5px 0 5px;

    .h-icon-down {
      right: 20px;
    }

    cursor: pointer;

    &:hover, &.h-pop-trigger {
      background: @hover-background-color;
    }

    &-dropdown {
      padding: 5px 0;

      .h-dropdownmenu-item {
        padding: 8px 20px;
      }
    }
  }

  &-menus {
    display: inline-block;
    vertical-align: top;

    > div {
      display: inline-block;
      font-size: 15px;
      padding: 0 25px;
      color: @dark-color;

      &:hover {
        color: @primary-color;
      }

      + div {
        margin-left: 5px;
      }

      &.h-tab-selected {
        color: @white-color;
        background-color: @primary-color;
      }
    }
  }
}

.as-icon-calendar {
  border: 1px solid #d3d3d3 !important;
  border-left: none !important;
  border-radius: 0 5px 5px 0 !important;
  height: 34px !important;

  &:before {
    vertical-align: middle;
  }
}

.d-input {
  height: 34px !important;
  width: 96px !important;
  padding-right: 0 !important;
  border-right: none !important;
  border-radius: 5px 0 0 5px !important;
}

.h-avatar-info {
  padding-left: 38px !important;
}
</style>
